import { PageOptions } from '@graphcommerce/framer-next-pages'
import { hygraphPageContent, HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, LayoutHeader, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import {
  LayoutNavigation,
  LayoutNavigationProps,
  RowProduct,
  RowColumnTwo,
  RowRenderer,
  RowLinks,
} from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & {
  favoritesList: ProductListQuery
}
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, favoritesList } = props
  const page = pages?.[0]

  const favorite = favoritesList?.products?.items?.[0]

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      <LayoutHeader floatingMd floatingSm />

      {page && (
        <RowRenderer
          content={page.content}
          renderer={{
            RowProduct: (rowProps) => {
              const { identity } = rowProps

              if (identity === 'home-favorites')
                return (
                  <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
                )

              return (
                <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
              )
            },
            RowColumnTwo: (rowProps) => {
              const { identity } = rowProps

              if (identity === 'home-seo')
                return (
                  <RowColumnTwo
                    {...rowProps}
                    sx={(theme) => ({
                      color: 'text.disabled',
                      marginBottom: theme.spacings.xl,
                    })}
                    sxRenderer={{ paragraph: { typography: 'body2' } }}
                  />
                )

              return <RowColumnTwo {...rowProps} />
            },
            RowLinks: (rowProps) => {
              const { linksVariant } = rowProps

              if (linksVariant === 'LogoSwiper') {
                return (
                  <Box
                    sx={{
                      '& .RowLinks-title': {
                        display: 'none !important',
                      },
                    }}
                  >
                    <RowLinks {...rowProps} />
                  </Box>
                )
              }

              return <RowLinks {...rowProps} />
            },
          }}
        />
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({ query: LayoutDocument })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  const favoritesList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MjQ=' } } },
  })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      favoritesList: (await favoritesList).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
